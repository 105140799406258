<script lang="ts">

  import {requestedCameraState, requestedMicrophoneState, silentStore} from "../Stores/MediaStore";
  import {inviteUserActivated} from "../Stores/MenuStore";

  // Disable user invite
  inviteUserActivated.set(false);


  function toggleCam(): void {
    if ($silentStore) return;
    if ($requestedCameraState === true) {
      requestedCameraState.disableWebcam();
    } else {
      requestedCameraState.enableWebcam();
    }
  }

  function toggleMic(): void {
    if ($silentStore) return;
    if ($requestedMicrophoneState === true) {
      requestedMicrophoneState.disableMicrophone();
    } else {
      requestedMicrophoneState.enableMicrophone();
    }
  }

  function onKeyDown(e: KeyboardEvent): void {
      e.preventDefault()
      // adds mute and camera keyboard shortcuts
      if (e.key === "c") {
        toggleCam();
      }

      if (e.key === "m") {
        toggleMic();
      }
  }

  function onBeforeUnload(e: BeforeUnloadEvent): void {
    e.preventDefault();
    e.returnValue = '';
  }

</script>

<svelte:window
    on:keydown={onKeyDown}
    on:beforeunload={onBeforeUnload}
/>
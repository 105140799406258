import { ButtonClickedEvent } from "src/front/Api/Events/ButtonClickedEvent"

function createMuteButton(node: HTMLVideoElement) {
  const button = document.createElement("button");
  button.textContent = node.muted ? "🔇": "🔊";
  return button;
}

function createVolumeSlider() {
  const slider = document.createElement("input");
  slider.type = "range"
  slider.style.width = "30%";
  slider.style.paddingLeft = "10px";
  slider.min = "0";
  slider.max = "1";
  slider.step = "0.1";
  slider.addEventListener("click", (e) => {
    e.preventDefault();
    e.stopPropagation();
  })
  return slider;
}

export function withAudioControls(node: HTMLVideoElement) {
  const controls = document.createElement("div");
  controls.style.position = "absolute";
  controls.style.zIndex = "1000";
  controls.style.display = "flex";

  const button = createMuteButton(node);
  const slider = createVolumeSlider();
  const changeVolume = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    node.volume = Number((event.target as HTMLInputElement).value);
  };
  const toggleMute = (event: ButtonClickedEvent) => {

    event.preventDefault();
    event.stopPropagation();
    node.muted = !node.muted;
    button.textContent = node.muted ? "🔇": "🔊";
  };
  button.addEventListener("click", toggleMute);

  slider.addEventListener("input", changeVolume);
  controls.appendChild(button);
  controls.appendChild(slider);
  node.parentNode?.insertBefore(controls, node);

  return {
    destroy: () => {
      button.removeEventListener("click", toggleMute),
      slider.removeEventListener("input", changeVolume)
    },
  };
}